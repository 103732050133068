
























import { Component, Prop, Vue } from 'vue-property-decorator'

import { Media } from '@/types'
import { blobDataPath } from '@/util/blob-data-path.utility';

@Component
export default class VideoPlayerComponent extends Vue {
  @Prop() public playerData!: Media

  public show = true;

  public getSrcPath(src: string): string {
    return this.playerData.isExternal ? src : blobDataPath + src;
  }
}
