var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "copyright" }, [
      _vm._v(
        " " +
          _vm._s(_vm.DCSLanguageService.translate("Copyright")) +
          " © Dow (1995-" +
          _vm._s(_vm.year) +
          "). " +
          _vm._s(_vm.DCSLanguageService.translate("AllRights"))
      ),
      _c("br"),
      _vm._v(
        " ®™ " + _vm._s(_vm.DCSLanguageService.translate("Trademark")) + " "
      )
    ]),
    _c("div", { staticClass: "link-content" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.dow.com/en-us/contact-us.html",
            target: "_blank"
          }
        },
        [_vm._v(_vm._s(_vm.DCSLanguageService.translate("ContactUs")))]
      ),
      _vm._v(" | "),
      _c(
        "a",
        {
          attrs: {
            href: "https://legal.dow.com/en-us/privacy-statement",
            target: "_blank"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("PrivacyStatement")) +
              " "
          )
        ]
      ),
      _vm._v(" | "),
      _c(
        "a",
        {
          attrs: {
            href: "https://legal.dow.com/en-us/terms-of-use",
            target: "_blank"
          }
        },
        [_vm._v(_vm._s(_vm.DCSLanguageService.translate("TermsOfUse")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }