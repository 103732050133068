

































import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

@Component
export default class FooterComponent extends Vue {
  public DCSLanguageService = new DCSLanguageService();
  public year = new Date().getFullYear();
}
