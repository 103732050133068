var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "video-player-dialog-container",
      attrs: { width: "50vw" },
      on: {
        input: function(show) {
          return show || _vm.$emit("player-closed")
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "video",
        {
          attrs: {
            controls: "",
            autoplay: _vm.playerData.autoPlay,
            poster: _vm.getSrcPath(_vm.playerData.poster.url)
          }
        },
        [
          _vm._l(_vm.playerData.sources, function(source) {
            return _c("source", {
              key: source.hash,
              attrs: { src: _vm.getSrcPath(source.url), type: source.mime }
            })
          }),
          _vm._v(" Your browser doesn't support HTML5 video tag. ")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }